<template>
  <div class="intro">
    <p class="retro-font">
      <span id="typed-text"></span>
      <span id="cursor">|</span>
    </p>
    <p class="name-text lamp-effect">Kevin BATOMENE</p>
    <p class="title-text">Développeur full stack en formation</p>
    <p class="title-text-2">à Zone01 Rouen-Normandie</p>
    <div class="tech-logos">
      <h2 class="technos-text">Technologies utilisées pour ce portfolio:</h2>
      <div class="tech-icons">
        <i class="bx bxl-javascript" title="JavaScript"></i>
        <i class="bx bxl-nodejs" title="Node.js"></i>
        <i class="bx bxl-html5" title="HTML5"></i>
        <i class="bx bxl-css3" title="CSS3"></i>
        <i class="bx bxl-vuejs" title="Vue.js"></i>
        <i class="bx bxl-bootstrap" title="Bootstrap"></i>
      </div>
    </div>
    
    <!-- Bouton Entrez -->
    <a href="#resume" class="enter-button" @click="triggerRotateLogo">Entrez</a>
  </div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  setup() {
    const triggerRotateLogo = () => {
      window.dispatchEvent(new CustomEvent('rotate-logo'));
    };

    onMounted(() => {
      // Code pour l'animation du texte
      const messages = [
        "Hello World!",
        "Hello Visiteurs ?",
        "Hello Recruteur ?",
        "Hello Collaborateur ?"
      ];
      const typingSpeed = 100; // Vitesse de frappe en millisecondes
      const deleteSpeed = 100; // Vitesse d'effacement en millisecondes
      const pauseTime = 2000; // Temps de pause avant d'effacer le texte

      let messageIndex = 0;
      let charIndex = 0;
      const typedTextElement = document.getElementById("typed-text");
      const cursorElement = document.getElementById("cursor");

      function typeWriter() {
        cursorElement.style.visibility = 'visible'; // Assurez-vous que le curseur est visible
        const currentMessage = messages[messageIndex];
        if (charIndex < currentMessage.length) {
          typedTextElement.textContent += currentMessage.charAt(charIndex);
          charIndex++;
          setTimeout(typeWriter, typingSpeed);
        } else {
          setTimeout(deleteText, pauseTime);
        }
      }

      function deleteText() {
        const currentMessage = messages[messageIndex];
        if (charIndex > 0) {
          typedTextElement.textContent = currentMessage.substring(0, charIndex - 1);
          charIndex--;
          setTimeout(deleteText, deleteSpeed);
        } else {
          // Passer au message suivant
          messageIndex = (messageIndex + 1) % messages.length;
          charIndex = 0; // Réinitialiser l'index des caractères
          setTimeout(typeWriter, pauseTime);
        }
      }

      // Lancer l'animation de frappe
      typeWriter();
    });

    return {
      triggerRotateLogo
    };
  }
};
</script>

<style>
.retro-font {
  font-family: 'Courier New', Courier, monospace;
  font-size: 24px;
  color: #000;
}

#cursor {
  display: inline-block;
  width: 1px;
  background-color: #000;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
</style>

<style src="../assets/css/global.css" scoped></style>
<style src="../assets/css/intro.css"></style>
