<template>
  <div class="compétences-container">
    <b-container class="my-5">
      <b-row>
        <h3 class="comp-text">Compétences</h3>  
        <b-col md="6">
          <img src="../assets/img/kevin.png" alt="CV Kevin BATOMENE" class="cv-image" />
          <div class="cv-actions">
            <a :href="cvDownloadLink" download="CV Kevin BATOMENE.pdf" class="btn btn-primary-cv">Télécharger</a>
          </div>
        </b-col>

        <b-col md="5">
          <!-- Backend Skills -->

          <div class="skills-category">
            <h4 class="category-text">OS</h4>
            <div class="skill-bar">
              <label>Linux</label>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 75%;"></div>
              </div>
            </div>
            <div class="skill-bar">
              <label>Kali Linux</label>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 45%;"></div>
              </div>
            </div>
            <div class="skill-bar">
              <label>Mac OS</label>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 70%;"></div>
              </div>
            </div>
            <div class="skill-bar">
              <label>Windows</label>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 80%;"></div>
              </div>
            </div>
          </div>

          <div class="skills-category">
            <h4 class="category-text">Backend</h4>
            <div class="skill-bar">
              <label>Node.js</label>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 65%;"></div>
              </div>
            </div>
            <div class="skill-bar">
              <label>Rust</label>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 55%;"></div>
              </div>
            </div>
            <div class="skill-bar">
              <label>Golang</label>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 75%;"></div>
              </div>
            </div>
          </div>

          <!-- Frontend Skills -->
          <div class="skills-category">
            <h4 class="category-text">Frontend</h4>
            <div class="skill-bar">
              <label>HTML5</label>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 90%;"></div>
              </div>
            </div>
            <div class="skill-bar">
              <label>CSS3</label>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 80%;"></div>
              </div>
            </div>
            <div class="skill-bar">
              <label>JavaScript</label>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 75%;"></div>
              </div>
            </div>
          </div>

          <!-- Frameworks Skills -->
          <div class="skills-category">
            <h4 class="category-text">Frameworks</h4>
            <div class="skill-bar">
              <label>Vue.js</label>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 72%;"></div>
              </div>
            </div>
            <div class="skill-bar">
              <label>Bootstrap</label>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 65%;"></div>
              </div>
            </div>
            <div class="skill-bar">
              <label>React</label>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 51.5%;"></div>
              </div>
            </div>
          </div>

          <div class="skills-category">
            <h4 class="category-text">Data base</h4>
            <div class="skill-bar">
              <label>SqLite</label>
              <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 54%;"></div>
              </div>
            </div>
          </div>
          

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>
export default {
  data() {
    return {
      cvDownloadLink: 'path/to/your-cv.pdf', // Chemin vers le fichier PDF de votre CV
      emailLink: 'mailto:your.email@example.com?subject=Prise de contact', // Remplacez par votre adresse email
    };
  },
};
</script>


<style src="../assets/css/competences.css"></style>
<style src="../assets/css/contact.css"></style>
<style>
.skill-bar {
  margin-bottom: 15px;
}

.progress {
  height: 20px;
}

.progress-bar {
  background-color: #007bff;
}
</style>
