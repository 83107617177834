<template>
  <div class="resume-container">
    <h3 class="resume-text">Résumé</h3>
    <b-container class="resume mt-5">
      <img src="../assets/img/Format-LinkedIn01_1_-removebg-preview.png" alt="" class="photo">
      <b-row class="align-items-center">
        <b-col md="4" class="text-center">
        </b-col>
        <b-col md="8">
          <div class="caroussel">
            <div class="list">
              <div class="item">
                <div class="info-container">
                  <p>Titulaire d'un baccalauréat professionnel en logistique, 
                    j'ai acquis une expérience dans le secteur agroalimentaire, 
                    développant des compétences en gestion des flux de marchandises et en 
                    optimisation logistique. 
                    Cette expérience m'a appris à planifier efficacement, 
                    à respecter les délais et les normes de sécurité et d'hygiène.</p>
                </div>
                <div class="info-container">
                  <p>Passionné par l'informatique, j'ai entrepris une reconversion vers 
                    le développement web et je suis actuellement en formation pour devenir 
                    développeur full-stack. J'ai acquis des compétences en HTML, CSS, 
                    JavaScript, ainsi qu'en frameworks comme React et Vue.js, me permettant 
                    de créer des solutions web efficaces et robustes.</p>
                </div>
                <div class="info-container">
                  <p>Je recherche une alternance pour appliquer mes compétences en développement 
                    web et approfondir mes connaissances. Je suis également ouvert à explorer 
                    d'autres technologies et outils, ce qui me permettra de m'adapter aux besoins 
                    de projets diversifiés et de contribuer à des solutions innovantes au sein de votre équipe.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="arrows">
            <button id="previous">&lt;</button> 
            <button id="next">&gt;</button>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="logos-skills">
        <!-- Section des technologies maîtrisées -->
        <div>
          <p class="text-logo">Autres technologies pratiqués:</p>
        </div>
        <div class="resume-logos">
          <img src="../assets/img/icons8-docker-48.png" title="Docker" class="resume-logo">
          <img src="../assets/img/icons8-microsoft-excel-2019-48.png" title="Excel" class="resume-logo">
          <img src="../assets/img/icons8-fl-studio-48.png" title="Fl Studio" class="resume-logo">
          <img src="../assets/img/icons8-git-48.png" title="Git" class="resume-logo">
          <img src="../assets/img/icons8-power-point-48.png" title="Power point" class="resume-logo">
          <img src="../assets/img/icons8-adobe-premiere-pro-48(1).png" title="Adobe premiere" class="resume-logo">
          <img src="../assets/img/icons8-rekordbox-100.png" title="Rekordbox" class="resume-logo">
          <img src="../assets/img/icons8-swift-48.png" title="Swift" class="resume-logo">
          <img src="../assets/img/icons8-visual-studio-48.png" title="VSCode" class="resume-logo">
          <img src="../assets/img/icons8-microsoft-word-2019-48.png" title="Word" class="resume-logo">
        </div>
        <!-- Section des soft skills -->
        <div>
          <p class="text-skills">Soft skills:</p>
        </div>
        <div class="skills-logos">
          <img src="../assets/img/conflict-resolution.png" title="Gestion de conflits" class="skills-logo">
          <img src="../assets/img/creative-design.png" title="Créativité" class="skills-logo">
          <img src="../assets/img/know-how.png" title="Résolution de problèmes" class="skills-logo">
          <img src="../assets/img/team.png" title="Travail en équipe" class="skills-logo">
        </div>
      </div>
  </div>
</template>

<script>
  document.addEventListener('DOMContentLoaded', function () {
  const list = document.querySelector('.list');
  const containers = document.querySelectorAll('.info-container');
  const totalContainers = containers.length;
  let currentIndex = 0;
  let autoScrollInterval;

  // Function to update the transform property for sliding effect
  function updateSlide() {
    list.style.transform = `translateX(-${currentIndex * 100}%)`;
  }

  // Function to handle next button click
  function showNextContainer() {
    currentIndex = (currentIndex + 1) % totalContainers;
    updateSlide();
  }

  // Function to handle previous button click
  function showPreviousContainer() {
    currentIndex = (currentIndex - 1 + totalContainers) % totalContainers;
    updateSlide();
  }

  // Automatic scrolling
  function startAutoScroll() {
    autoScrollInterval = setInterval(showNextContainer, 15000);
  }

  function stopAutoScroll() {
    clearInterval(autoScrollInterval);
  }

  // Event listeners for buttons
  document.getElementById('next').addEventListener('click', function () {
    stopAutoScroll();
    showNextContainer();
    startAutoScroll();
  });

  document.getElementById('previous').addEventListener('click', function () {
    stopAutoScroll();
    showPreviousContainer();
    startAutoScroll();
  });

  // Start the automatic scrolling
  startAutoScroll();
});

</script>


<style src="../assets/css/global.css" scoped></style>
<style src="../assets/css/resume.css"></style>