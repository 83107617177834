<template>
    <div :class="['window', windowClass]" :style="windowStyle"></div>
  </template>

<script>
    export default {
  name: 'AppWindow',
  props: {
    image: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: '200px'
    },
    height: {
      type: String,
      default: '200px'
    },
    top: {
      type: String,
      required: true
    },
    left: {
      type: String,
      required: true
    }
  },
  computed: {
    windowStyle() {
      return {
        width: this.width,
        height: this.height,
        top: this.top,
        left: this.left,
        backgroundImage: `url(${this.image})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        zIndex: 1
      };
    }
  }
};
</script>

<style src="../assets/css/global.css"></style>
<style src="../assets/css/window.css"></style>