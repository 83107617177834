<template>
  <div>
    <div class="background" @mousemove="handleMouseMove">
      <div class="light" :style="{ left: lightX + 'px', top: lightY + 'px' }"></div>
    </div>
    <AppNavbar/>
    <AppIntro id="accueil"/>
      <AppWindow/>
    <AppResume id="resume"/>
    <AppComp id="compétences"/>
    <AppCard id="portfolio"/>
    <button v-if="showScrollToTop" @click="handleScrollToTopAndRotateLogo" class="scroll-to-top">
      <i class='bx bx-chevron-up'></i>
    </button>
    <AppContact id="contact"/>
    <AppFooter/>
  </div>
</template>

<script>
import AppNavbar from './components/AppNavbar.vue'
import AppIntro from './components/AppIntro.vue'
import AppResume from './components/AppResume.vue'
import AppFooter from './components/AppFooter.vue'
import AppCard from './components/AppCard.vue'
import AppContact from './components/AppContact.vue'
import AppComp from './components/AppComp.vue'
import AppWindow from './components/AppWindow.vue'

export default {
  name: 'App',
  components: {
    AppNavbar,
    AppIntro,
    AppResume,
    AppFooter,
    AppCard,
    AppContact,
    AppComp,
    AppWindow
  },
  data() {
    return {
      lightX: 0,
      lightY: 0,
      showScrollToTop: false,
    };
  },
  methods: {
    handleMouseMove(event) {
      this.lightX = event.clientX - 150;
      this.lightY = event.clientY - 150;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    rotateLogo() {
      window.dispatchEvent(new CustomEvent('rotate-logo'));
    },
    handleScrollToTopAndRotateLogo() {
      this.scrollToTop();
      this.rotateLogo();
    },
    handleScroll() {
      this.showScrollToTop = window.scrollY > 200; // Affiche le bouton si le défilement dépasse 200px
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style src="../src/assets/css/global.css" scoped></style>
<style src="../src/assets/css/background.css"></style>
<style src="../src/assets/css/intro.css"></style>
<style src="../src/assets/css/top.css"></style>
<style src="../src/assets/css/window.css"></style>

<style scoped>
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff; 
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-to-top i {
  font-size: 24px; 
  color: #fff; 
}

.scroll-to-top:hover {
  background-color: #0056b3;
}
</style>
