<template>
  <div class="portfolio-container">
  <h3 class="comp-text">Portfolio</h3>  
  <div class="card-container">
    <div class="card1">
      <b-card
        title="Ascii art web - stylize"
        :img-src="require('@/assets/img/port_img2.jpg')"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem;"
        class="mb-2"  
      >
      <b-card-text>
        Ascii-art-web nous apprend à écrire en Go un programme prenant une chaîne et un modèle de bannière ASCII, respectant les bonnes pratiques et format d'utilisation spécifique.
      </b-card-text>
      <b-button href="https://github.com/kompozitterz/ascii-art-web-stylize"  target="_blank" variant="primary">Github</b-button>
        <div class="logo-container2" style="display: flex; justify-content: space-around; margin-top: 10px;">
          <img :src="require('@/assets/img/golang-1.svg')" class="golang-logo" title="Golang" alt="Logo 1" style="max-width: 50px;">
          <img :src="require('@/assets/img/html-1.svg')" class="html-logo" title="Html" alt="Logo 1" style="max-width: 50px;">
          <img :src="require('@/assets/img/css-3.svg')" class="css-logo" title="Css" alt="Logo 1" style="max-width: 50px;">
        </div>
      </b-card>
    </div>

    <div class="card2">
      <b-card
        title="Groupie tracker geoloc"
        :img-src="require('@/assets/img/port_img3.png')"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem;"
        class="mb-2"  
      >
      <b-card-text>
        Dans groupie tracker, nous allons manipuler les données d'une API pour créer un site web affichant des informations sur des artistes, leurs concerts, et leurs dates via diverses visualisations interactives.
      </b-card-text>
        <b-button href="https://github.com/kompozitterz/groupie-tracker"  target="_blank" variant="primary">Github</b-button>
        <div class="logo-container2" style="display: flex; justify-content: space-around; margin-top: 10px;">
          <img :src="require('@/assets/img/golang-1.svg')" class="golang-logo" title="Golang" alt="Logo 1" style="max-width: 50px;">
          <img :src="require('@/assets/img/html-1.svg')" class="html-logo" title="Html" alt="Logo 1" style="max-width: 50px;">
          <img :src="require('@/assets/img/css-3.svg')" class="css-logo" title="Css" alt="Logo 1" style="max-width: 50px;">
        </div>
      </b-card>
    </div>

    <div class="card3">
      <b-card
        title="Lem-in"
        :img-src="require('@/assets/img/port_img4.jpg')"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem;"
        class="mb-2"  
      >
      <b-card-text>
        Lem-in consiste à coder une version numérique d'une fourmilière, lisant un fichier pour trouver et afficher le chemin le plus rapide pour que des fourmis traversent une colonie composée de salles et de tunnels.
      </b-card-text>
        <b-button href="https://github.com/kompozitterz/lem-in" target="_blank" variant="primary">Github</b-button>
        <div class="logo-container2" style="display: flex; justify-content: space-around; margin-top: 10px;">
          <img :src="require('@/assets/img/golang-1.svg')" class="golang-logo" title="Golang" alt="Logo 1" style="max-width: 50px;">  
        </div>
      </b-card>
    </div>
    
    <div class="card4">
      <b-card
        title="Make your game - History"
        :img-src="require('@/assets/img/port_img5.jpg')"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem;"
        class="mb-2"  
      >
      <b-card-text>
        Développer un jeu en JavaScript pur avec un taux de rafraîchissement de 60 FPS, intégrant un menu de pause et un tableau des scores. Inspiré de Pac-Man, le jeu doit éviter les baisses de fréquence d'images.
      </b-card-text>
        <b-button href="#" variant="primary">Github</b-button>
        <div class="logo-container2" style="display: flex; justify-content: space-around; margin-top: 10px;">
          <img :src="require('@/assets/img/golang-1.svg')" class="golang-logo" title="Golang" alt="Logo 1" style="max-width: 50px;">
          <img :src="require('@/assets/img/html-1.svg')" class="html-logo" title="Html" alt="Logo 1" style="max-width: 50px;">
          <img :src="require('@/assets/img/css-3.svg')" class="css-logo" title="Css" alt="Logo 1" style="max-width: 50px;">
        </div>
      </b-card>
    </div>

    <div class="card5">
      <b-card
        title="Road intersection"
        :img-src="require('@/assets/img/port_img6.jpg')"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem;"
        class="mb-2"  
      >
      <b-card-text>
        Road intersection est une simulation de trafic à une intersection de deux routes, comprenant des feux de circulation et des véhicules respectant les règles de circulation.
      </b-card-text>
        <b-button href="https://github.com/kompozitterz/road-intersection" target="_blank" variant="primary">Github</b-button>
        <div class="logo-container2" style="display: flex; justify-content: space-around; margin-top: 10px;">
          <img :src="require('@/assets/img/rust.svg')" class="rust-logo" title="Rust" alt="Logo 1" style="max-width: 50px;">
        </div>
      </b-card>
    </div>

    <div class="card6">
      <b-card
        title="Social network"
        :img-src="require('@/assets/img/port_img7.jpg')"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem;"
        class="mb-2"  
      >
      <b-card-text>
        Ce projet est un réseau social intégrant des profils, des publications, des groupes, des notifications et des discussions, avec un frontend basé sur un framework JavaScript et un backend géré via SQLite et Docker.
      </b-card-text>
        <b-button href="#" variant="primary">Github</b-button>
        <div class="logo-container2" style="display: flex; justify-content: space-around; margin-top: 10px;">
          <img :src="require('@/assets/img/javascript-1.svg')" class="rust-logo" title="JavaScript" alt="Logo 1" style="max-width: 50px;">
          <img :src="require('@/assets/img/html-1.svg')" class="html-logo" title="Html" alt="Logo 1" style="max-width: 50px;">
          <img :src="require('@/assets/img/css-3.svg')" class="css-logo" title="Css" alt="Logo 1" style="max-width: 50px;">
          <img :src="require('@/assets/img/vue-9.svg')" class="css-logo" title="Vue.js" alt="Logo 1" style="max-width: 50px;">
          <img :src="require('@/assets/img/docker-4.svg')" class="docker-logo" title="Docker" alt="Logo 1" style="max-width: 50px;">
          <img :src="require('@/assets/img/sqlite.svg')" class="sqlite-logo" title="SqLite" alt="Logo 1" style="max-width: 50px;">
        </div>
      </b-card>
    </div>
    
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'AppCard',
    props: {
      title: {
        type: String,
        required: true
      },
      text: {
        type: String,
        required: true
      },
      imgSrc: {
        type: String,
        default: ''
      },
      imgAlt: {
        type: String,
        default: 'Card image cap'
      },
      btnText: {
        type: String,
        default: ''
      },
      btnLink: {
        type: String,
        default: '#'
      },
      width: {
        type: String,
        default: ''
      }
    }
  }
  </script>
  


<style src="../assets/css/global.css" scoped></style>
<style src="../assets/css/card.css"></style>