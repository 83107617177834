<template>
  <div class="contact-container">
    <b-container class="my-5">
      <b-row>
        <b-col class="social-links">
          <h3 class="social-text-1">Suivez moi</h3>
          <div class="d-flex justify-content-around">
            <a href="https://github.com/kompozitterz" target="_blank">
              <i class="fab fa-github social-icon"></i>
            </a>
            <a href="https://www.linkedin.com/in/kevin-batomene-ba7574285/" target="_blank">
              <i class="fab fa-linkedin social-icon"></i>
            </a>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="email-link">
          <h3 class="social-text-2">Contactez moi</h3>
          <div class="d-flex justify-content-center mt-4">
            <a href="mailto:tonadresseemail@example.com" class="btn btn-primary">Envoyer un mail</a>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col class="school-link">
          <h3 class="social-text-3">En savoir plus sur mon école</h3>
          <img src="../assets/img/O1logo.png" alt="Logo de l'école" class="img-fluid my-3" style="max-width: 200px;">
          <div class="d-flex justify-content-center mt-4">
            <a href="https://zone01rouennormandie.org/" target="_blank" class="btn btn-secondary">Visitez le site de l'école</a>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
</script>

<style src="../assets/css/global.css" scopped></style>
<style src="../assets/css/contact.css"></style>
