<template>
  <div class="nav">
    <ul>
      <img src="../assets/img/LOGO_OPOTABLE_JPEG_1_-removebgLightGreen.png" id="logo-navbar" :class="{ rotating: isRotating }" @animationend="isRotating = false">
      <li><a @click.prevent="scrollToSection('resume')" href="#resume" class="btn_resume" @click="rotateLogo">Résumé</a></li>
      <li><a @click.prevent="scrollToSection('compétences')" href="#compétences" class="btn_compétences" @click="rotateLogo">Compétences</a></li>
      <li><a @click.prevent="scrollToSection('portfolio')" href="#portfolio" class="btn_portfolio" @click="rotateLogo">Portfolio</a></li>
      <li><a @click.prevent="scrollToSection('contact')" href="#contact" class="btn_contact" @click="rotateLogo">Contact</a></li>
    </ul>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue';

export default {
  name: 'AppNavbar',
  setup() {
    const isRotating = ref(false);

    const rotateLogo = () => {
      isRotating.value = true;
    };

    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Register event listener
    const handleRotateLogo = () => {
      rotateLogo();
    };

    onMounted(() => {
      window.addEventListener('rotate-logo', handleRotateLogo);
    });

    onUnmounted(() => {
      window.removeEventListener('rotate-logo', handleRotateLogo);
    });

    return {
      isRotating,
      rotateLogo,
      scrollToSection
    };
  }
};
</script>



<style src="../assets/css/global.css"  scoped></style>
<style src="../assets/css/navbar.css"></style>
